import { DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { ValidationError } from "../../../common/validationError";
import { useTranslation } from "../../../localization/useTranslation";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import { useSystemStep } from "./SystemStep.hooks";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { SystemStatus } from "../SystemEdit.types";
import TagsMultiSelect from "../../../common/components/tagsMultiSelect/TagsMultiSelect";
import { TagEntityType } from "../../../common/components/tagsMultiSelect/TagsMultiSelect.types";
import PlanInputFieldWrapper from "../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import { PurpleLockIcon } from "../../../common/icons";

export interface ISystemStep {
    systemId: string;
    systemName?: string;
    description?: string;
    status: SystemStatus;
    validationErrors: Array<ValidationError>;
    onSystemNameChange: (name: string) => void;
    onDescriptionChange: (description: string) => void;
    onStatusChange: (status: SystemStatus) => void;
    readOnly?: boolean;
}

export function SystemStep(props: ISystemStep) {
    const { translateString } = useTranslation();
    const { systemTypeOptions } = useSystemStep();
    const { informationSecurityFeatures, addonsFeatures } = usePlanContext();

    return (
        <StepSegment size="small">
            <DotLegalTextField
                label={translateString("systemNameLabel")}
                value={props.systemName ?? ""}
                onChange={(name) => props.onSystemNameChange(name)}
                disabled={props.readOnly}
                toolTipText={translateString("systemNameTooltip")}
                errorText={props.validationErrors.find((v) => v.field === "systemName")?.error}
                debounce
            />

            <DotLegalTextField
                label={translateString("description")}
                multiline
                rows={12}
                value={props.description ?? ""}
                disabled={props.readOnly}
                onChange={(description) => props.onDescriptionChange(description)}
                toolTipText={translateString("systemDescriptionTooltip")}
                debounce
            />

            {!addonsFeatures.research && (
                <PlanInputFieldWrapper hasAccess={informationSecurityFeatures.systemStatus} hoverText={translateString("upgradeInfoSecPlan")}>
                    <DotLegalSelect
                        label={translateString("systemStatus")}
                        placeholder={translateString("systemStatus")}
                        selectedItem={props.status.toString()}
                        options={systemTypeOptions}
                        onChange={(status) => props.onStatusChange(Number(status))}
                        disableClearable
                        toolTipText={translateString("systemStatusTooltip")}
                        noOptionsLabel={translateString("noOptions")}
                        disabled={props.readOnly || !informationSecurityFeatures.systemStatus}
                        icon={!informationSecurityFeatures.systemStatus ? PurpleLockIcon : undefined}
                    />
                </PlanInputFieldWrapper>
            )}

            <TagsMultiSelect entityId={props.systemId} entityType={TagEntityType.System} disabled={props.readOnly} />
        </StepSegment>
    );
}
