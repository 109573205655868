import { getEnumValues } from "../../../../common/enumOperations";
import useCountryHook from "../../../../common/hooks/useCountryList";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../../common/stringOperations";
import { ValidationError } from "../../../../common/validationError";
import { useTranslation } from "../../../../localization/useTranslation";
import { LegalEntityType } from "../../../addLegalEntityDialog/AddLegalEntityDialog.types";
import { LegalEnityManagementModel } from "../../LegalEntityManagement.types";

export function useLegalEntityDescriptionStepHook() {
    const { getSelectableCountries, countriesIsLoading } = useCountryHook();
    const { translateString } = useTranslation();

    function getLegalEntityTypeOptions() {
        return getEnumValues(LegalEntityType).map((x) => {
            var name = translateString(setFirstLetterToLowerCase(LegalEntityType[x].toString()));
            return { name: name, id: x.toString() };
        });
    }

    return { getSelectableCountries, countriesIsLoading, getLegalEntityTypeOptions };
}

export function useValidateDescriptionStep() {
    const { translateString } = useTranslation();

    return (model: LegalEnityManagementModel): Array<ValidationError> => {
        const result = [];
        if (isNullOrWhitespace(model.name)) {
            result.push(new ValidationError("legalEntityName", translateString("nameRequired")));
        }

        return result;
    };
}
