import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, Typography } from "@mui/material";
import { useSharingsAgreementBoxHooks } from "./sharingsAgreementBox.hooks";
import AgreementDialog from "../agreementDialog/DataProcessorAgreementDialog";
import { StepTypeEnum } from "../../ProcessingActivity.types";
import { DataProcessorAgreementSaveModel } from "../agreementDialog/DataProcessorAgreementDialog.types";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useSharingsAgreeementStyles } from "./sharingsAgreementBox.styles";
import { isBool } from "../../../common/booleanOperations";
import DotLegalMultiSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalMultiSelectWithButton";

export interface ISharingsAgreementBox {
    showAddAgreementBox: boolean;
    getSelectedDocuments: string[];
    options: DotLegalSelectOption[] | undefined;
    saveAgreement: (agreement: string, dataprocessor: string) => void;
    id: string | undefined;
    disabled?: boolean;
    validationError?: string;
    onYesOrNoChange: (hasAgreement: boolean) => void;
    dataProcessorAgreementSaveModel: DataProcessorAgreementSaveModel | undefined;
    onAddNewAgreementClick: () => void;
    stepType: StepTypeEnum;
    onChipClick?: ((optionId: string) => void) | undefined;
    singleSelect?: boolean | undefined;
    isLoading?: boolean;
    onAddedAgreement: (agreementId: string) => void;
    onDeletedAgreement: (agreementId: string) => void;
}

export default function SharingsAgreementBox(props: ISharingsAgreementBox) {
    const { dataProtectionFeatures } = usePlanContext();
    const styles = useSharingsAgreeementStyles(!dataProtectionFeatures.dataProcessingAgreements);
    const { translateString } = useTranslation();
    const { showAddAgreementDialog, setShowAddAgreementDialog } = useSharingsAgreementBoxHooks(props);

    const onClickAgreementLink = dataProtectionFeatures.dataProcessingAgreements
        ? () => {
              props.onAddNewAgreementClick();
              setShowAddAgreementDialog(true);
          }
        : () => {};

    let dataprocessorAgreementSelect = (
        <DotLegalMultiSelectWithButton
            chosenOptions={props.getSelectedDocuments}
            isLoading={props.isLoading}
            label={translateString("agreement")}
            disabled={props.disabled}
            placeholder={translateString("agreement")}
            toolTipText={translateString("agreementHelperText")}
            singleSelect={props.singleSelect}
            errorText={props.validationError}
            options={props.options}
            disableClearable
            noOptionsLabel={translateString("noOptions")}
            onChange={(options) => {
                if (props.singleSelect) {
                    const isBooleanValue = options.find((x) => isBool(x));
                    if (isBooleanValue) {
                        const hasAgreement = isBooleanValue.toLowerCase() === "true";

                        props.onYesOrNoChange(hasAgreement);
                    } else {
                        const agreement = options[0];
                        props.onAddedAgreement(agreement);
                    }
                }
            }}
            onChipClick={props.onChipClick}
            onItemAdded={props.singleSelect ? undefined : (item) => props.onAddedAgreement(item)}
            onItemDeleted={props.singleSelect ? undefined : (item) => props.onDeletedAgreement(item)}
            buttonLabel={translateString("createAgreement")}
            onButtonClick={onClickAgreementLink}
            hasPermission={props.showAddAgreementBox && dataProtectionFeatures.dataProcessingAgreements}
        />
    );

    const getAgreementLink = () => {
        const link = props.disabled ? (
            <></>
        ) : (
            <Typography onClick={onClickAgreementLink} sx={styles.descriptionLabel}>
                {translateString("createAgreement")}
            </Typography>
        );

        if (!dataProtectionFeatures.dataProcessingAgreements) {
            return <DotLegalTooltip text={translateString("upgradeDataProtectionPlan")}>{link}</DotLegalTooltip>;
        }

        return link;
    };

    return (
        <>
            {props.showAddAgreementBox ? (
                <Box sx={styles.container}>
                    {dataprocessorAgreementSelect}
                    {getAgreementLink()}

                    {showAddAgreementDialog && !props.isLoading && !isNullOrWhitespace(props.id) && (
                        <AgreementDialog
                            stepType={props.stepType}
                            onDialogClose={() => setShowAddAgreementDialog(false)}
                            saveModel={props.dataProcessorAgreementSaveModel!}
                            onSave={(agreementId) => props.saveAgreement(agreementId, props.id!)}
                        />
                    )}
                </Box>
            ) : (
                <>{dataprocessorAgreementSelect}</>
            )}
        </>
    );
}
