import { LegalEntityMasterType } from "../legalEntities/LegalEntities.types";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "../legalEntityManagement/LegalEntityManagement.types";
import { LegalEntityRiskAssessment } from "../legalEntityRiskQuestionnaire/LegalEntityRiskQuestionnaire.types";

export interface LegalEntityModel {
    name: string;
    address: string;
    city: string;
    zipCode: string;
    companyRegistrationNumber: string;
    isCustomerOwned: boolean;
    isActive: boolean;
    country: string;
    levelOfProtection: LevelOfProtectionType | null;
    note: string;
    riskAssessment: LegalEntityRiskAssessment | null;
    type: LegalEntityType | null;
    isTIAUnnecessary: boolean;
    isTransferBasisUnnecessary: boolean;
    addDataPrivacyFrameworkToSharings: boolean;
    certifications: Array<string>;
    areas: Array<string>;
    isThirdCountry: boolean;
    website: string | null;
    responsible: string | null;
    businessAreas: Array<string>;
    types: Array<LegalEntityType>;
    masterTypes: Array<LegalEntityMasterType>;
    auditType?: LegalEntityAuditType;
    auditFrequency?: LegalEntityAuditFrequency;
    numberOfDocuments: number;
}

export class ExternalLegalEntityModel {
    name: string = "";
    address: string = "";
    city: string = "";
    zipCode: string = "";
    companyRegistrationNumber: string = "";
    country: string = "";
    levelOfProtection: LevelOfProtectionType | null = null;
    types: Array<LegalEntityType> = [];
    isTIAUnnecessary: boolean = false;
    isTransferBasisUnnecessary: boolean = false;
    isApproved: boolean;
    website: string = "";

    constructor(isApproved: boolean) {
        this.isApproved = isApproved;
    }
}

export interface LegalEntitySaveModel {
    name: string;
    address?: string;
    city?: string;
    zipCode?: string;
    companyRegistrationNumber?: string;
    country?: string;
    levelOfProtection: LevelOfProtectionType;
    types: Array<LegalEntityType>;
    isTIAUnnecessary: boolean;
    isTransferBasisUnnecessary: boolean;
    isApproved: boolean;
    website: string;
}

export interface LegalEntityCategory {
    name: string;
    id: string;
}

export interface CountryModel {
    countryCode: string;
    isThirdCountry: boolean;
}

export enum LevelOfProtectionType {
    None,
    AdequateProtection,
    EU,
    PrivacyShield,
    EEA,
    ThirdCountry,
    CPIEDA,
    DataPrivacyFramework,
}

export interface CreationResult {
    id: "string";
    isCreated: boolean;
}

export enum LegalEntityType {
    Vendor = 1,
    Customer = 2,
    Authority = 3,
    CollaborationPartner = 4,
    Other = 5,
}
