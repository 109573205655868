import { Box, IconButton, MenuItem, Popover } from "@mui/material";
import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../localization/useTranslation";
import { hasAccessToSystemSteps, useSystemOverviewHook } from "./SystemOverview.hooks";
import { editIcon } from "../../common/icons";
import { useSystemOverviewStyles } from "./SystemOverview.styles";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import SystemDeleteDialog from "./systemDeleteDialog/SystemDeleteDialog";
import UpdateSystemTemplateDialog from "../updateSystemTemplateDialog/UpdateSystemTemplateDialog";
import { isTemplateCustomer } from "../../common/templateCustomerHelper";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import SystemDocumentTab from "./systemDocuments/SystemDocumentsTab";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";
import SystemSharingTab from "./systemSharingsTab/SystemSharingsTab";
import GeneralTabContent from "./generalTabContent/GeneralTabContent";
import DataTabContent from "./dataTabContent/DataTabContent";
import SecurityTabContent from "./securityTabContent/SecurityTabContent";
import { dotLegalTabPanelNoPadding } from "../../common/components/dotLegalTabPanel/DotLegalTabPanel";
import SystemOverviewNoteDialog from "./systemOverviewNoteDialog/SystemOverviewNoteDialog";
import SystemControlProcedureDialog from "./systemControlProcedureDialog/SystemControlProcedureDialog";
import { DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import SystemAssociatedInternalAssessmentTab from "./systemAssociatedInternalAssessmentTab/SystemAssociatedInternalAssessmentTab";
import SystemClassificationTab from "./systemClassificationTab/SystemClassificationTab";
import PlanMenuItemWrapper from "../../plan/planMenuItemWrapper/PlanMenuItemWrapper";
import { getPlanTab } from "../../plan/planTab/PlanTab";

function SystemOverview() {
    const { permissions, customerName } = useUserContext();
    const { translateString } = useTranslation();
    const { informationSecurityFeatures, hasVendorManagementAccess, vendorManagementFeatures, addonsFeatures } = usePlanContext();

    const {
        infoData,
        getBreadCrumbs,
        anchorEl,
        setAnchorEl,
        open,
        popoverId,
        editUrl,
        showDeleteSystemDialog,
        setShowDeleteSystemDialog,
        deleteSystemSuccess,
        showEditSystemTemplateInfo,
        setShowEditSystemTemplateInfo,
        id,
        selectedTab,
        setSelectedTab,
        selectedPaperTab,
        setSelectedPaperTab,
        showNoteDialog,
        setShowNoteDialog,
        showInternalControlProcedureDialog,
        setShowInternalControlProcedureDialog,
        refetchSystemInfo,
    } = useSystemOverviewHook();

    const styles = useSystemOverviewStyles();

    const getTabContent = () => {
        const content: Array<TabContent> = [
            {
                label: translateString("sharings"),
                content: getSharingsTab(),
                index: 0,
            },
        ];

        content.push({
            label: `${translateString("documents")} ${informationSecurityFeatures.systemDocuments && infoData && infoData.numberOfDocuments > 0 ? `(${infoData.numberOfDocuments})` : ""}`,
            content: getDocumentsTab(),
            index: 1,
            disabled: !informationSecurityFeatures.systemDocuments,
            icon: !informationSecurityFeatures.systemDocuments ? (
                <Box sx={{ marginLeft: 0.5, marginTop: 1 }}>
                    <PurpleLockSvg />
                </Box>
            ) : undefined,
            tooltip: !informationSecurityFeatures.systemDocuments ? translateString("upgradeInfoSecPlan") : undefined,
        });

        if (hasVendorManagementAccess && permissions.canManageInternalAssessment) {
            content.push(
                getPlanTab(
                    translateString("internalAssessments"),
                    getInternalAssesments(),
                    2,
                    vendorManagementFeatures.processingActivityAssociateInternalAssessments,
                    translateString("upgradeVendorManagementPlan")
                )
            );
        }

        if (hasVendorManagementAccess && (permissions.canManageGroupEntities || permissions.companyPermissions)) {
            content.push(
                getPlanTab(
                    translateString("classifications"),
                    <SystemClassificationTab systemId={id!} />,
                    3,
                    vendorManagementFeatures.systemClassifications,
                    translateString("upgradeVendorManagementPlan")
                )
            );
        }

        return content;
    };

    const getSharingsTab = () => {
        return <SystemSharingTab systemId={id} />;
    };

    const getDocumentsTab = () => {
        return <SystemDocumentTab systemId={id} refetch={() => refetchSystemInfo()} />;
    };

    const getInternalAssesments = () => {
        return <SystemAssociatedInternalAssessmentTab systemId={id!} systemName={infoData?.name ?? ""} />;
    };

    const getPaperContent = (tabIndex: number) => {
        switch (tabIndex) {
            case 0:
                return <GeneralTabContent systemId={id} systemName={infoData?.name ?? ""} />;
            case 1:
                return <DataTabContent systemId={id} />;
            case 2:
                return <SecurityTabContent systemId={id} />;
        }
    };

    const getPaperWithContent = (tabIndex: number) => {
        return (
            <Box sx={{ "& .MuiPaper-root": { borderTopLeftRadius: 0, marginTop: -dotLegalTabPanelNoPadding } }}>
                <DotLegalPaper background="purpleWave1" backgroundCover removePadding>
                    {getPaperContent(tabIndex)}
                </DotLegalPaper>
            </Box>
        );
    };

    // TODO - Efter permissions er lavet i frontend skal vi erstatte edit knap med <PageHeaderEditEntityButton
    // Se PolicyOverview for implementation
    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{
                    customerName: customerName,
                }}
                breadCrumbs={getBreadCrumbs()}
                rightComponent={
                    <Box sx={styles.gridContentAlignment}>
                        <Box sx={styles.editButton}>
                            <IconButton
                                color={"primary"}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                                aria-haspopup="true"
                            >
                                {editIcon}
                            </IconButton>

                            <Popover
                                id={popoverId}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                            >
                                <React.Fragment>
                                    {hasAccessToSystemSteps(permissions) && (
                                        <DotLegalLink to={editUrl} noUnderlineOnHover>
                                            <MenuItem
                                                sx={styles.menuItem}
                                                onClick={() => {
                                                    setAnchorEl(null);
                                                }}
                                            >
                                                {translateString("editSystem")}
                                            </MenuItem>
                                        </DotLegalLink>
                                    )}
                                    {permissions.systemPermissions.delete && (
                                        <MenuItem
                                            sx={styles.menuItem}
                                            onClick={() => {
                                                setAnchorEl(null);
                                                setShowDeleteSystemDialog(true);
                                            }}
                                        >
                                            {translateString("deleteSystem")}
                                        </MenuItem>
                                    )}
                                    {permissions.canManageCustomers && isTemplateCustomer() && (
                                        <MenuItem
                                            sx={styles.menuItem}
                                            onClick={() => {
                                                setAnchorEl(null);
                                                setShowEditSystemTemplateInfo(true);
                                            }}
                                        >
                                            {translateString("editSystemTemplate")}
                                        </MenuItem>
                                    )}
                                    {!addonsFeatures.research && (
                                        <PlanMenuItemWrapper
                                            hasAccess={informationSecurityFeatures.internalControlProcedures}
                                            menuItem={
                                                <MenuItem
                                                    sx={styles.menuItem}
                                                    onClick={() => {
                                                        setAnchorEl(null);
                                                        setShowInternalControlProcedureDialog(true);
                                                    }}
                                                >
                                                    {translateString("systemInternalControlProcedureHeader")}
                                                </MenuItem>
                                            }
                                            menuName={translateString("systemInternalControlProcedureHeader")}
                                            tooltip={translateString("upgradeInfoSecPlan")}
                                        />
                                    )}

                                    <MenuItem onClick={() => setShowNoteDialog(true)}>{translateString("note")}</MenuItem>
                                </React.Fragment>
                            </Popover>
                        </Box>
                    </Box>
                }
            />

            <DotLegalTabs
                tabStyle={"paper-tabs"}
                selectedTab={selectedPaperTab ?? 0}
                setSelectedTab={setSelectedPaperTab}
                content={[
                    {
                        label: translateString("general"),
                        content: getPaperWithContent(0),
                        index: 0,
                    },
                    {
                        label: "Data",
                        content: getPaperWithContent(1),
                        index: 1,
                    },
                    {
                        label: translateString("securityHeader"),
                        content: getPaperWithContent(2),
                        index: 2,
                    },
                ]}
                fullWidth={false}
                noPadding
            />

            <Box sx={styles.tableContainer}>
                <DotLegalTabs
                    fullWidth={false}
                    tabStyle="secondary"
                    selectedTab={selectedTab ?? 0}
                    setSelectedTab={setSelectedTab}
                    content={getTabContent()}
                    noPadding
                    ignoreTextTransformation
                />
            </Box>

            {showDeleteSystemDialog && (
                <SystemDeleteDialog
                    systemId={infoData!.id}
                    systemName={infoData!.name}
                    onCloseDialog={() => setShowDeleteSystemDialog(false)}
                    onSuccessDelete={() => deleteSystemSuccess()}
                />
            )}

            {showEditSystemTemplateInfo && <UpdateSystemTemplateDialog systemId={infoData!.id} closeDialog={() => setShowEditSystemTemplateInfo(false)} />}

            {showNoteDialog && (
                <SystemOverviewNoteDialog
                    disabled={!permissions.systemPermissions.edit}
                    systemId={infoData!.id}
                    onCloseDialog={() => setShowNoteDialog(false)}
                    onSuccess={() => setShowNoteDialog(false)}
                />
            )}

            {showInternalControlProcedureDialog && <SystemControlProcedureDialog onDialogClose={() => setShowInternalControlProcedureDialog(false)} />}
        </Box>
    );
}

export default SystemOverview;
