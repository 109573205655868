import React, { useState } from "react";
import { usePrivacyDrawerStyles } from "./PrivacyDrawer.styles";
import { Box, Drawer, IconButton } from "@mui/material";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import MenuIcon from "@mui/icons-material/Menu";
import NavMenu from "../NavMenu/NavMenu";
import { arrowLeft, arrowRight, privacyLogo } from "../../common/icons";
import { topBarHeight } from "../NavMenu/NavMenu.styles";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";

export interface IPrivacyDrawerProps {
    ribbonShown?: boolean;
    isExpandedBigScreen: boolean;
    isExpandedSmallScreen: boolean;
    setIsExpandedBigScreen: (expanded: boolean) => void;
    setIsExpandedSmallScreen: (expanded: boolean) => void;
}

function PrivacyDrawer(props: IPrivacyDrawerProps) {
    const isOnSmallScreen = useIsOnSmallScreen();
    let open = isOnSmallScreen ? props.isExpandedSmallScreen : props.isExpandedBigScreen;
    const styles = usePrivacyDrawerStyles(open);

    const [hoveringOnDrawer, setHoveringOnDrawer] = useState(false);

    let drawerVariant: "permanent" | "persistent" | "temporary" | undefined = "permanent";
    if (isOnSmallScreen) {
        drawerVariant = "temporary";
    }

    return (
        <React.Fragment>
            {isOnSmallScreen && (
                <div className="react-left-menu">
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => props.setIsExpandedSmallScreen(!props.isExpandedSmallScreen)}>
                        <MenuIcon />
                    </IconButton>
                </div>
            )}
            <Drawer
                sx={[styles.drawer, open ? styles.drawerExpanded : {}]}
                variant={drawerVariant}
                open={open}
                onClose={toggleExpanded}
                onMouseEnter={() => setHoveringOnDrawer(true)}
                onMouseLeave={() => setHoveringOnDrawer(false)}
                PaperProps={{
                    sx: { position: props.ribbonShown ? "flex !important" : "fixed" },
                }}
            >
                {/* This outer box seemingly does nothing, but for some reason without it, the logo box will change it's size, moving the icons slightly, when pulling the drawer in and out */}
                <Box sx={{ height: topBarHeight }}>
                    <Box sx={[(theme) => ({ height: theme.spacing(10) }), styles.logo]}>
                        {(isOnSmallScreen || props.isExpandedBigScreen) && <DotLegalLink to={"/"}>{privacyLogo}</DotLegalLink>}
                        {(isOnSmallScreen || hoveringOnDrawer || !props.isExpandedBigScreen) && (
                            <Box sx={styles.expandButton} onClick={toggleExpanded}>
                                {!props.isExpandedBigScreen && arrowRight}
                                {props.isExpandedBigScreen && arrowLeft}
                            </Box>
                        )}
                    </Box>
                </Box>
                <NavMenu
                    isExpandedBigScreen={props.isExpandedBigScreen}
                    isExpandedSmallScreen={props.isExpandedSmallScreen}
                    hoveringOnDrawer={hoveringOnDrawer}
                    isExpanded={isOnSmallScreen ? true : props.isExpandedBigScreen}
                    onLinkClick={() => props.setIsExpandedSmallScreen(false)}
                    trialRibbonShown={props.ribbonShown}
                />
            </Drawer>
        </React.Fragment>
    );

    function toggleExpanded() {
        if (isOnSmallScreen) {
            props.setIsExpandedSmallScreen(!props.isExpandedSmallScreen);
        } else {
            props.setIsExpandedBigScreen(!props.isExpandedBigScreen);
        }
    }
}

export default PrivacyDrawer;
