import { Box } from "@mui/material";
import usePublicInternalAssessmentInitiationHooks from "./PublicInternalAssessmentInitiation.hooks.ts";
import React from "react";
import { DotLegalButton, DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { arrow } from "../../../common/icons";
import PublicInternalAssessmentContainer from "./PublicInternalAssessmentContainer.tsx";

function PublicInternalAssessmentInitiation() {
    const { validation, updater, send, model, isSending } = usePublicInternalAssessmentInitiationHooks();
    const { translateString } = useTranslation();
    validation.check(model);
    return (
        <PublicInternalAssessmentContainer>
            <DotLegalHeader headerStyle={"xxxl"}>{translateString("startNewAssessment")}</DotLegalHeader>
            <Box sx={{ width: "100%", alignItems: "center" }}>
                <DotLegalTextField
                    label={""}
                    placeholder={translateString("firstName")}
                    value={model.firstName}
                    debounce={false}
                    errorText={validation.getErrorsForDisplay("firstName")}
                    onChange={updater.onFirstNameChanged}
                />
                <DotLegalTextField
                    label={""}
                    placeholder={translateString("lastName")}
                    value={model.lastName}
                    debounce={false}
                    errorText={validation.getErrorsForDisplay("lastName")}
                    onChange={updater.onLastNameChanged}
                />
                <DotLegalTextField
                    label={""}
                    placeholder={translateString("emailAddress")}
                    value={model.email}
                    debounce={false}
                    errorText={validation.getErrorsForDisplay("email")}
                    onChange={updater.onEmailChanged}
                />

                <Box sx={{ display: "flex", marginTop: "40px", justifyContent: "center" }}>
                    <DotLegalButton isLoading={isSending} buttonType={"primary"} btnLength={150} onClick={() => send()}>
                        <Box>
                            {translateString("start")} &nbsp;{arrow}
                        </Box>
                    </DotLegalButton>
                </Box>
            </Box>
        </PublicInternalAssessmentContainer>
    );
}

export default PublicInternalAssessmentInitiation;
