import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { convertEnumToStringOrNull } from "../../common/enumOperations";
import { useTranslation } from "../../localization/useTranslation";
import { PolicySaveModel } from "../policies/Policies.types";
import { usePolicyDialogDataMapping } from "./PolicyDialog.hooks";
import { UserSelectableItem } from "../../user/User.types";
import TagsMultiSelect from "../../common/components/tagsMultiSelect/TagsMultiSelect";
import { TagEntityType } from "../../common/components/tagsMultiSelect/TagsMultiSelect.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";

export interface IPolicyDialog {
    onDialogClose: () => void;
    onSave: () => void;
    selectedPolicy: PolicySaveModel;
    selectableResponsibles: (responsible: string | null | undefined) => UserSelectableItem[] | undefined;
    isResponsiblesLoading: boolean;
}

function PolicyDialog(props: IPolicyDialog) {
    const { translateString } = useTranslation();
    const { typeOptions, saveModel, setSaveModel, savePolicy, validation, isSaving, isEdit } = usePolicyDialogDataMapping(props);
    const { platformFeatures } = usePlanContext();

    validation?.check(saveModel);

    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            header={isEdit ? translateString("editPolicy") : translateString("createPolicy")}
            onOkClick={async () => {
                savePolicy();
            }}
            onDialogClose={props.onDialogClose}
            open
            disableBackdropOnCloseIfValueChange={saveModel}
            okButtonDisabled={saveModel.tags === undefined && platformFeatures.tags}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("name")}
                value={saveModel?.name ?? ""}
                errorText={validation?.getErrorsForDisplay("name")}
                onChange={(name) => {
                    let tempViewModel = { ...saveModel };
                    tempViewModel.name = name;
                    setSaveModel(tempViewModel);
                }}
            />
            <DotLegalSelect
                label={translateString("type")}
                options={typeOptions}
                placeholder={""}
                disableClearable
                errorText={validation?.getErrorsForDisplay("type")}
                selectedItem={convertEnumToStringOrNull(saveModel.type)}
                onChange={(type) => {
                    let tempViewModel = { ...saveModel };
                    tempViewModel.type = Number(type);
                    setSaveModel(tempViewModel);
                }}
                noOptionsLabel={translateString("noOptions")}
            />
            <DotLegalSelect
                options={props.selectableResponsibles(saveModel.responsibleId)}
                isLoading={props.isResponsiblesLoading}
                selectedItem={saveModel.responsibleId}
                placeholder={""}
                label={translateString("responsible")}
                onChange={(responsible) => {
                    setSaveModel({ ...saveModel, responsibleId: responsible });
                }}
                noOptionsLabel={translateString("noOptions")}
            />

            <TagsMultiSelect
                entityId={props.selectedPolicy.id}
                entityType={TagEntityType.Policy}
                customControl={{
                    onChange: (tags) => {
                        let tempViewModel = { ...saveModel };
                        tempViewModel.tags = tags;
                        setSaveModel(tempViewModel);
                    },
                    selectedTags: saveModel.tags ?? [],
                }}
            />
        </DotLegalDialog>
    );
}

export default PolicyDialog;
