import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { GdprIncidentLogEntryOverviewViewModel } from "../IncidentLogDialog.types";
import { useParams } from "react-router-dom";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { useState } from "react";

export function useIncidentLogOverviewDataMapping() {
    const { id } = useParams<{ id: string }>();
    const url = `/gdprIncidentLogEntry/${id}/overview`;
    const query = useQuery(`gdprIncidentLogEntryoverview${id}`, () => get<GdprIncidentLogEntryOverviewViewModel>(url));
    const [selectedTab, setSelectedTab] = useStateUrlParamsArray("tab", ["0"]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [showDataCategoryDialog, setShowDataCategoryDialog] = useState(false);
    const [showIncidentTypeDialog, setShowIncidentTypeDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showConsequencesDialog, setShowConsequencesDialog] = useState(false);
    const [showSecurityMeasuresDialog, setShowSecurityMeasuresDialog] = useState(false);
    const { data } = query;

    return {
        isLoading: query.isLoading,
        data,
        anchorEl,
        setAnchorEl,
        selectedTab,
        setSelectedTab,
        open: Boolean(anchorEl),
        popoverId: Boolean(anchorEl) ? "simple-popover" : undefined,
        showDataCategoryDialog,
        setShowDataCategoryDialog,
        showIncidentTypeDialog,
        setShowIncidentTypeDialog,
        showDeleteDialog,
        setShowDeleteDialog,
        showEditDialog,
        setShowEditDialog,
        refetch: query.refetch,
        showConsequencesDialog,
        setShowConsequencesDialog,
        showSecurityMeasuresDialog,
        setShowSecurityMeasuresDialog,
    };
}
