import { DotLegalFullScreenSpinner, DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import useLegalEntityManagementHooks from "./LegalEntityManagement.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import DotLegalStepper from "../../common/components/dotLegalStepper/DotLegalStepper";
import LegalEntityDescriptionStep from "./steps/legalEntityDescriptionStep/LegalEntityDescriptionStep";
import LegalEntityAssociationStep from "./steps/legalEntityAssociationStep/LegalEntityAssociationStep";
import LegalEntityAreaAndCertificationsStep from "./steps/legalEntityAreaAndCertificationsStep/LegalEntityAreaAndCertificationsStep";
import { LegalEntityPageEnum } from "../legalEntities/LegalEntities.types";
import { useLegalEntityBreadCrumbs } from "../useLegalEntityBreadCrumbs.ts";
import LegalEntityAuditStep from "./steps/legalEntityAuditStep/LegalEntityAuditStep.tsx";

export interface ILegalEntityManagement {
    legalEntityPage: LegalEntityPageEnum;
}

function LegalEntityManagement(props: ILegalEntityManagement) {
    const { isLoading, steps, activeStep, changeStep, legalEntity, legalEntityUpdater, onSaveLegalEntity, saveClicked, validationErrors } =
        useLegalEntityManagementHooks(props);
    const { customerName } = useUserContext();
    const breadCrumbs = useLegalEntityBreadCrumbs(props.legalEntityPage, { name: legalEntity?.name ?? "", id: legalEntity?.id ?? "" });

    function getStepContent(currentStep: number) {
        switch (currentStep) {
            case 0:
                return {
                    childElement: (
                        <LegalEntityDescriptionStep
                            legalEntity={legalEntity}
                            onNameChange={legalEntityUpdater.onNameChange}
                            onAddressChange={legalEntityUpdater.onAddressChange}
                            onZipCodeChange={legalEntityUpdater.onZipCodeChange}
                            onCityChange={legalEntityUpdater.onCityChange}
                            onCompanyRegistrationNumberChange={legalEntityUpdater.onCompanyRegistrationNumberChange}
                            onWebsiteChange={legalEntityUpdater.onWebsiteChange}
                            onTypesChange={legalEntityUpdater.onTypesChange}
                            onCountryChange={legalEntityUpdater.onCountryChange}
                            onIsApprovedChange={legalEntityUpdater.onIsApprovedChange}
                            validationErrors={validationErrors}
                        />
                    ),
                    graphicType: undefined,
                };
            case 1:
                return {
                    childElement: (
                        <LegalEntityAssociationStep
                            businessAreaIds={legalEntity.businessAreaIds}
                            responsible={legalEntity.responsible}
                            onResponsibleChange={legalEntityUpdater.onResponsibleChange}
                            onBusinessAreaChange={legalEntityUpdater.onBusinessAreaChange}
                        />
                    ),
                };
            case 2:
                return {
                    childElement: (
                        <LegalEntityAuditStep
                            onAuditTypeChange={legalEntityUpdater.onAuditTypeChange}
                            onAuditFrequencyChange={legalEntityUpdater.onAuditFrequencyChange}
                            auditFrequency={legalEntity.auditFrequency}
                            auditType={legalEntity.auditType}
                        />
                    ),
                };
            case 3:
                return {
                    childElement: (
                        <LegalEntityAreaAndCertificationsStep
                            onCertificationChange={legalEntityUpdater.onCertificationChange}
                            onComplianceAreaChange={legalEntityUpdater.onComplianceAreaChange}
                            certificationIds={legalEntity.certificationIds}
                            complianceAreaIds={legalEntity.complianceAreaIds}
                        />
                    ),
                };
            default:
                return {
                    childElement: <div>Unknown stepIndex</div>,
                    graphicType: undefined,
                };
        }
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName: customerName }} breadCrumbs={breadCrumbs} />
            {isLoading ? (
                <React.Fragment>
                    <DotLegalFullScreenSpinner />
                </React.Fragment>
            ) : (
                <DotLegalStepper
                    steps={steps}
                    activeStep={activeStep.stepType}
                    header={activeStep.name}
                    inProgess={saveClicked}
                    onStepClick={(step) => changeStep(step)}
                    onSaveClick={async () => onSaveLegalEntity()}
                >
                    {getStepContent(activeStep.stepType).childElement}
                </DotLegalStepper>
            )}
        </React.Fragment>
    );
}

export default LegalEntityManagement;
