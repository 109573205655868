import {
    DotLegalCheckbox,
    DotLegalDatePicker,
    DotLegalDialog,
    DotLegalHeader,
    DotLegalMultiSelect,
    DotLegalRadioButton,
    DotLegalSelect,
    DotLegalTextField,
    DotLegalTimePicker,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Box, RadioGroup, Stack } from "@mui/material";
import React from "react";
import { useCreateEditGdprIncidentLogDialogDataMapping } from "./CreateEditGdprIncidentLogDialog.hooks";
import DotLegalTextFieldWithSnippets from "../../common/components/dotLegalTextFieldWithSnippets/DotLegalTextFieldWithSnippets";
import { useCreateEditGdprIncidentStyles } from "./CreateEditGdprIncidentLogDialog.styles";
import TagsMultiSelect from "../../common/components/tagsMultiSelect/TagsMultiSelect";
import { TagEntityType } from "../../common/components/tagsMultiSelect/TagsMultiSelect.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";

export interface ICreateEditGdprIncidentDialogProps {
    closeDialog: () => void;
    onSave: () => void;
    id?: string | undefined;
}

function CreateEditGdprIncidentLogDialog(props: ICreateEditGdprIncidentDialogProps) {
    const { translateString, getDateTimePickerLanguage } = useTranslation();
    const { platformFeatures } = usePlanContext();
    const {
        saveModel,
        selectedIncidentTypes,
        setSelectedIncidentTypes,
        setSaveModel,
        dataCategoriesData,
        validateAndSave,
        errors,
        consequenceSuggestions,
        securityMeasures,
        timeOfIncident,
        setTimeOfIncident,
        incidentTypes,
        isEdit,
        isLoading,
        isSaving,
        severityLevelSelectOptions,
    } = useCreateEditGdprIncidentLogDialogDataMapping(props);
    const styles = useCreateEditGdprIncidentStyles();
    const getMinDate = () => {
        return new Date(2015, 0, 1);
    };
    const getMaxDate = () => {
        return new Date();
    };
    const datePickerLanguage = getDateTimePickerLanguage();

    return (
        <DotLegalDialog
            open
            header={isEdit() ? translateString("incidentDialogEditHeader") : translateString("incidentDialogCreateHeader")}
            onDialogClose={props.closeDialog}
            size={"md"}
            buttonOkText={translateString("save")}
            onOkClick={() => {
                validateAndSave();
            }}
            isLoading={isLoading}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={saveModel}
            okButtonDisabled={saveModel.tags === undefined && platformFeatures.tags}
        >
            <Box sx={styles.container}>
                <Box sx={styles.subContainer}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentNameDescription")}>
                        {translateString("incidentName")}
                    </DotLegalHeader>
                    <DotLegalTextField
                        label={""}
                        placeholder={translateString("incidentNameTextFieldPlaceholder")}
                        value={saveModel?.name}
                        debounce={false}
                        onChange={(value) => {
                            let tempModel = { ...saveModel, name: value };
                            setSaveModel(tempModel);
                        }}
                        errorText={errors?.name}
                        noMargin
                    />
                </Box>
                <Box sx={styles.subContainer}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentTimeDescription")}>
                        {translateString("incidentTime")}
                    </DotLegalHeader>
                    <Stack direction={"row"}>
                        <Box sx={{ mr: 1, flex: 1 }}>
                            <DotLegalDatePicker
                                label=""
                                noMargin
                                value={saveModel?.dateOfIncident}
                                onChange={(value) => {
                                    let tempModel = { ...saveModel, dateOfIncident: value };
                                    tempModel.unknownDateOfIncident = false;
                                    setSaveModel(tempModel);
                                }}
                                view={"day"}
                                minDate={getMinDate()}
                                maxDate={getMaxDate()}
                                language={datePickerLanguage}
                            />
                        </Box>
                        <Box sx={{ ml: 1, flex: 1 }}>
                            <DotLegalTimePicker
                                value={timeOfIncident}
                                valueConversion={"utcToLocal"}
                                onChange={(value) => {
                                    let tempModel = { ...saveModel };
                                    tempModel.unknownDateOfIncident = false;
                                    setSaveModel(tempModel);
                                    setTimeOfIncident(value);
                                }}
                                language={datePickerLanguage}
                            />
                        </Box>
                    </Stack>
                    <DotLegalCheckbox
                        label={translateString("incidentTimeUnknown")}
                        checked={saveModel?.unknownDateOfIncident}
                        onChange={(value) => {
                            let tempModel = { ...saveModel, unknownDateOfIncident: value };
                            if (value) {
                                tempModel.dateOfIncident = null;
                                setTimeOfIncident(null);
                            }
                            setSaveModel(tempModel);
                        }}
                        margin={{
                            marginLeft: -1,
                            marginBottom: -1,
                            marginRight: 0,
                            marginTop: 0,
                        }}
                    />
                </Box>
                <Box sx={styles.subContainer}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentCauseDescription")}>
                        {translateString("incidentCause")}
                    </DotLegalHeader>
                    <DotLegalTextField
                        label={""}
                        placeholder={translateString("incidentCauseTextFieldPlaceholder")}
                        value={saveModel?.cause}
                        debounce={false}
                        multiline
                        rows={3}
                        onChange={(value) => {
                            let tempModel = { ...saveModel, cause: value };
                            setSaveModel(tempModel);
                        }}
                        noMargin
                    />

                    <TagsMultiSelect
                        entityId={props.id}
                        entityType={TagEntityType.IncidentLog}
                        customControl={{
                            onChange: (tags) => {
                                let tempViewModel = { ...saveModel };
                                tempViewModel.tags = tags;
                                setSaveModel(tempViewModel);
                            },
                            selectedTags: saveModel.tags ?? [],
                        }}
                    />
                </Box>

                <Box sx={styles.subContainer}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentTypeDescription")}>
                        {translateString("incidentType")}
                    </DotLegalHeader>
                    <DotLegalMultiSelect
                        options={incidentTypes}
                        chosenOptions={selectedIncidentTypes}
                        onChange={(types) => {
                            setSelectedIncidentTypes(types);
                        }}
                        placeholder={translateString("incidentTypeDropdown")}
                        label={""}
                        noOptionsLabel={""}
                        noMargin
                    />
                    <Box sx={{ mt: 2 }}>
                        <DotLegalTextField
                            label={translateString("incidentTypeTextFieldPlaceholder")}
                            value={saveModel?.description}
                            debounce={false}
                            multiline
                            rows={3}
                            onChange={(value) => {
                                let tempModel = { ...saveModel, description: value };
                                setSaveModel(tempModel);
                            }}
                            noMargin
                        />
                    </Box>
                </Box>
                <Box sx={styles.subContainer}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentDataCategoryDescription")}>
                        {translateString("incidentDataCategory")}
                    </DotLegalHeader>
                    <DotLegalMultiSelect
                        options={dataCategoriesData}
                        chosenOptions={saveModel?.dataCategories}
                        onChange={(types) => {
                            let tempModel = { ...saveModel };
                            tempModel.dataCategories = types;
                            setSaveModel(tempModel);
                        }}
                        placeholder={translateString("incidentDataCategorySelectorPlaceholder")}
                        label={""}
                        noOptionsLabel={""}
                        alwaysOpenInBottom
                        listMaxHeight={190}
                        noMargin
                    />
                </Box>
                <Box sx={styles.subContainer}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentSeverityLevelDescription")}>
                        {translateString("severity")}
                    </DotLegalHeader>
                    <DotLegalSelect
                        selectedItem={saveModel.severity.toString()}
                        options={severityLevelSelectOptions}
                        onChange={(severity) => {
                            let tempModel = { ...saveModel };
                            tempModel.severity = Number(severity);
                            setSaveModel(tempModel);
                        }}
                        label={""}
                        noOptionsLabel={""}
                        alwaysOpenInBottom
                        listMaxHeight={190}
                        noMargin
                        disableClearable
                    />
                </Box>
                <Box sx={{ ...styles.subContainer, mb: 4 }}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentConsequenceDescription")}>
                        {translateString("incidentConsequence")}
                    </DotLegalHeader>
                    <DotLegalTextFieldWithSnippets
                        snippets={consequenceSuggestions}
                        debounce={false}
                        value={saveModel?.consequences}
                        label={""}
                        variant={"outlined"}
                        error={""}
                        placeholder={translateString("incidentConsequenceTextFieldPlaceholder")}
                        rows={5}
                        onChange={(value) => {
                            let tempModel = { ...saveModel, consequences: value };
                            setSaveModel(tempModel);
                        }}
                        select={translateString("incidentConsequenceSuggestions")}
                        disableValidation
                        noMargin
                        limitHeight
                    ></DotLegalTextFieldWithSnippets>
                </Box>
                <Box sx={{ ...styles.subContainer, mb: 4 }}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"} toolTip={translateString("incidentMeasureDescription")}>
                        {translateString("incidentMeasure")}
                    </DotLegalHeader>
                    <DotLegalTextFieldWithSnippets
                        snippets={securityMeasures}
                        debounce={false}
                        value={saveModel?.measures}
                        label={""}
                        variant={"outlined"}
                        error={""}
                        placeholder={translateString("incidentMeasureTextFieldPlaceholder")}
                        rows={5}
                        onChange={(value) => {
                            let tempModel = { ...saveModel, measures: value };
                            setSaveModel(tempModel);
                        }}
                        select={translateString("incidentMeasureSuggestions")}
                        disableValidation
                        noMargin
                        limitHeight
                    ></DotLegalTextFieldWithSnippets>
                </Box>
                <Box sx={styles.subContainer}>
                    <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                        {translateString("incidentAgencyReport")}
                    </DotLegalHeader>
                    <RadioGroup
                        row
                        value={saveModel?.reportedToAgency === null ? null : saveModel?.reportedToAgency ? 1 : 0}
                        onChange={(e) => {
                            let tempModel = { ...saveModel };
                            const notified = Number((e.target as HTMLInputElement).value) === 1;
                            tempModel.reportedToAgency = notified;
                            if (notified) {
                                tempModel.reasonForNotReportingToAgency = null;
                            } else {
                                tempModel.dateOfReportedToAgency = null;
                            }

                            setSaveModel(tempModel);
                        }}
                    >
                        <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("yes")} />
                        <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("no")} />
                    </RadioGroup>
                    {saveModel?.reportedToAgency !== null &&
                        (saveModel?.reportedToAgency ? (
                            <Box>
                                <DotLegalHeader marginBottom={1} headerStyle={"extraSmall"}>
                                    {translateString("incidentAgencyReportDate")}
                                </DotLegalHeader>

                                <DotLegalDatePicker
                                    label={""}
                                    noMargin
                                    value={saveModel?.dateOfReportedToAgency}
                                    onChange={(value) => {
                                        let tempModel = { ...saveModel, dateOfReportedToAgency: value };
                                        setSaveModel(tempModel);
                                    }}
                                    minDate={getMinDate()}
                                    maxDate={getMaxDate()}
                                    language={datePickerLanguage}
                                />
                            </Box>
                        ) : (
                            <>
                                <DotLegalHeader marginBottom={1} headerStyle={"extraSmall"}>
                                    {translateString("incidentAgencyReportReason")}
                                </DotLegalHeader>
                                <DotLegalTextField
                                    label={""}
                                    value={saveModel?.reasonForNotReportingToAgency}
                                    debounce={false}
                                    multiline
                                    rows={3}
                                    onChange={(value) => {
                                        let tempModel = { ...saveModel, reasonForNotReportingToAgency: value };
                                        setSaveModel(tempModel);
                                    }}
                                    noMargin
                                />
                            </>
                        ))}
                </Box>
                <Box>
                    <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                        {translateString("incidentAffectedPeopleNotified")}
                    </DotLegalHeader>
                    <RadioGroup
                        row
                        value={saveModel?.affectedPeopleNotified === null ? null : saveModel?.affectedPeopleNotified ? 1 : 0}
                        onChange={(e) => {
                            let tempModel = { ...saveModel };
                            const notified = Number((e.target as HTMLInputElement).value) === 1;
                            tempModel.affectedPeopleNotified = notified;
                            if (notified) {
                                tempModel.reasonForNotNotifyingAffectedPeople = null;
                            } else {
                                tempModel.dateOfAffectedPeopleNotified = null;
                            }
                            setSaveModel(tempModel);
                        }}
                    >
                        <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("yes")} />
                        <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("no")} />
                    </RadioGroup>
                    {saveModel?.affectedPeopleNotified !== null &&
                        (saveModel?.affectedPeopleNotified ? (
                            <Box>
                                <DotLegalHeader marginBottom={1} headerStyle={"extraSmall"}>
                                    {translateString("incidentAffectedPeopleNotifiedDate")}
                                </DotLegalHeader>

                                <DotLegalDatePicker
                                    label={""}
                                    noMargin
                                    value={saveModel?.dateOfAffectedPeopleNotified}
                                    onChange={(value) => {
                                        let tempModel = { ...saveModel, dateOfAffectedPeopleNotified: value };
                                        setSaveModel(tempModel);
                                    }}
                                    minDate={getMinDate()}
                                    maxDate={getMaxDate()}
                                    language={datePickerLanguage}
                                />
                            </Box>
                        ) : (
                            <>
                                <DotLegalHeader marginBottom={1} headerStyle={"extraSmall"}>
                                    {translateString("incidentAffectedPeopleNotifiedReason")}
                                </DotLegalHeader>
                                <DotLegalTextField
                                    label={""}
                                    value={saveModel?.reasonForNotNotifyingAffectedPeople}
                                    debounce={false}
                                    multiline
                                    rows={3}
                                    onChange={(value) => {
                                        let tempModel = { ...saveModel, reasonForNotNotifyingAffectedPeople: value };
                                        setSaveModel(tempModel);
                                    }}
                                    noMargin
                                />
                            </>
                        ))}
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default CreateEditGdprIncidentLogDialog;
