import { StepModel } from "../../../common/components/dotLegalStepper/DotLegalCoreStepper.types";
import { useSteps } from "../../../common/components/dotLegalStepper/useSteps";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { LegalEnityManagementModel } from "../LegalEntityManagement.types";

export function useLegalEntityManagementSteps(legalEntity: LegalEnityManagementModel, legalEntitySteps: Array<StepModel>) {
    const steps = useSteps(legalEntity, legalEntitySteps, "step", HasStepDataAdded);

    function HasStepDataAdded(step: number) {
        switch (step) {
            case 0:
                return !isNullOrWhitespace(legalEntity.name);
            case 1:
                return !isNullOrWhitespace(legalEntity.responsible) && legalEntity.businessAreaIds.length > 0;
            case 2:
                return auditStepHasData();
            case 3:
                return legalEntity.complianceAreaIds.length > 0;
            default:
                return true;
        }
    }

    function auditStepHasData() {
        if (legalEntity.auditType && legalEntity.auditFrequency) {
            return true;
        }
        return false;
    }

    return {
        getSteps: steps.getLegalEntityManagementSteps,
        getActiveStep: steps.getActiveStep,
        changeStep: steps.changeStep,
        validationErrors: steps.validationErrors,
    };
}
