import { DotLegalBreadCrumbModel, DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, CircularProgress, IconButton, Link, MenuItem, Popover } from "@mui/material";
import React from "react";
import { editIcon } from "../../../common/icons";
import { useAuditGroupHeader } from "./AuditGroupHeader.hooks";
import { useTranslation } from "../../../localization/useTranslation";
import CreateLegalEntityAuditGroupDialog from "../../createLegalEntityAuditGroupDialog/CreateLegalEntityAuditGroupDialog";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../../useUrlProvider";
import { AuditGroupEditModel } from "./AuditGroupHeader.types";
import DeleteLegalEntityAuditGroupDialog from "../../deleteLegalEntityAuditGroupDialog/DeleteLegalEntityAuditGroupDialog";

export interface AuditGroupHeaderProps {
    breadCrumbs: Array<DotLegalBreadCrumbModel>;
    customerName: string;
    auditGroupModel: AuditGroupEditModel | undefined;
    onAuditGroupUpdated: () => void;
    showAddLegalEntityBtn: boolean;
    showDeleteMenuItem: boolean;
    canDeleteAuditgroup: boolean;
}

function AuditGroupHeader(props: AuditGroupHeaderProps) {
    const navigate = useNavigate();
    const { translateString } = useTranslation();
    const {
        anchorEl,
        setAnchorEl,
        showEditDialog,
        setShowEditDialog,
        popoverId,
        openMenu,
        id,
        showDeleteDialog,
        setShowDeleteDialog,
        onDownloadManagementReportClick,
        isDownloadingManagementReport,
    } = useAuditGroupHeader(props);
    const { getAuditGroupDraftUrl, getAuditGroupsUrl, getAuditGroupManagementReportUrl } = useUrlProvider();

    // TODO - Efter permissions er lavet i frontend skal vi erstatte edit knap med <PageHeaderEditEntityButton
    // Se PolicyOverview for implementation
    return (
        <React.Fragment>
            <DotLegalPageHeader
                breadCrumbs={props.breadCrumbs}
                userContext={{
                    customerName: props.customerName,
                }}
                rightComponent={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                            sx={(theme) => ({
                                marginLeft: theme.spacing(0.5),
                                "& svg": {
                                    height: 25,
                                    stroke: theme.palette.primary.main,
                                    fill: theme.palette.primary.light,
                                },
                            })}
                        >
                            <IconButton
                                color={"primary"}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                                aria-haspopup="true"
                            >
                                {isDownloadingManagementReport ? <CircularProgress size={24} /> : editIcon}
                            </IconButton>

                            <Popover
                                id={popoverId}
                                open={openMenu}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        setShowEditDialog(true);
                                    }}
                                >
                                    {translateString("edit")}
                                </MenuItem>
                                {props.showAddLegalEntityBtn && (
                                    <MenuItem
                                        onClick={() => {
                                            setAnchorEl(null);
                                            navigate(getAuditGroupDraftUrl(id!));
                                        }}
                                    >
                                        {translateString("addLegalEntity")}
                                    </MenuItem>
                                )}
                                {props.showDeleteMenuItem && <MenuItem onClick={() => setShowDeleteDialog(true)}>{translateString("delete")}</MenuItem>}
                                {props.auditGroupModel && props.auditGroupModel.sent !== null && (
                                    <>
                                        <MenuItem onClick={() => setAnchorEl(null)}>
                                            <Link
                                                underline="none"
                                                href={getAuditGroupManagementReportUrl(props.auditGroupModel.id)}
                                                color="inherit"
                                                target="_blank"
                                            >
                                                {translateString("viewManagementReportInBrowser")}
                                            </Link>
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setAnchorEl(null);
                                                onDownloadManagementReportClick();
                                            }}
                                        >
                                            {translateString("downloadManagementReport")}
                                        </MenuItem>
                                    </>
                                )}
                            </Popover>
                        </Box>
                    </Box>
                }
            />

            {props.auditGroupModel && showEditDialog && (
                <CreateLegalEntityAuditGroupDialog
                    onDialogClose={() => setShowEditDialog(false)}
                    auditGroup={{
                        description: props.auditGroupModel.description,
                        name: props.auditGroupModel.name,
                        deadline: props.auditGroupModel.deadline,
                        groupEntityId: props.auditGroupModel.senderId,
                        questionnaireId: props.auditGroupModel.auditTemplateId,
                        questionnaireName: props.auditGroupModel.auditTemplateName,
                        questionnaireVersion: props.auditGroupModel.auditTemplateVersion,
                        responsibleId: props.auditGroupModel.responsibleId,
                        questionnaireIntroductionTitle: props.auditGroupModel.questionnaireIntroductionTitle,
                    }}
                    onAuditGroupUpdated={() => {
                        setShowEditDialog(false);
                        props.onAuditGroupUpdated();
                    }}
                    auditGroupId={props.auditGroupModel.id}
                    auditGroupInProgress={props.auditGroupModel.sent !== undefined && props.auditGroupModel.sent !== null}
                />
            )}

            {props.auditGroupModel && showDeleteDialog && (
                <DeleteLegalEntityAuditGroupDialog
                    auditGroupId={id!}
                    auditGroupName={props.auditGroupModel.name}
                    deadline={props.auditGroupModel.deadline}
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    onDeletedAuditGroup={() => navigate(getAuditGroupsUrl())}
                    canDeleteAuditGroup={props.canDeleteAuditgroup}
                />
            )}
        </React.Fragment>
    );
}

export default AuditGroupHeader;
