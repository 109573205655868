import { SelectableCompany } from "../../common/components/dotLegalCompanySelector/DotLegalCompanySelector.types";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";

export interface ProcessingActivityOverViewModel {
    processingActivityOverviewRows: Array<ProcessingActivityOverViewRow>;
    dataCategoryItems: Array<DotLegalSelectOption>;
    dataSubjectItems: Array<DotLegalSelectOption>;
    businessAreaItems: Array<DotLegalSelectOption>;
    purposeItems: Array<DotLegalSelectOption>;
    currentRiskAssessmentVersionId: string | undefined;
    hasMultipleGroupCompanies: boolean;
}

export interface ProcessingActivityValidationDueViewModel {
    processingActivityId: string;
    isDue: boolean;
}

export interface ProcessingActivityOverViewRow {
    id: string;
    userFriendlyId: string;
    name: string;
    systemCount: number;
    processingCount: number;
    transferCount: number;
    sourceCount: number;
    progress: number;
    canDelete: boolean;
    businessAreas: Array<DotLegalSelectOption>;
    businessAreaSorting: string;
    legalEntityCount: number;
    dataCategoryIds: Array<string>;
    dataSubjectIds: Array<string>;
    businessAreaIds: Array<string>;
    purposeIds: Array<string>;
    riskAssessmentTotalScore: number | undefined;
    scenariosScore: number | undefined;
    processingActivityStatus: ProcessingActivityStatus;
    owner: string | undefined;
    lastStatusUpdate: Date;
    approvelNumber: string | undefined;
    canValidateProcessingActivity: boolean;
}

export interface ProcessingActivitiesProgressViewModel {
    overallProgress: number;
    rows: Array<ProcessingActivityProgressRow>;
}

export interface ProcessingActivityProgressRow {
    id: string;
    progressScore: number;
    canValidateProcessingActivity: boolean;
}

export interface ProcessingActivityRiskRow {
    id: string;
    riskAssessmentTotalScore: number | undefined;
    scenariosScore: number | undefined;
}

export interface SelectedProcessingActivityReport {
    showDialog: boolean;
    processingActivityId: string;
    processingActivityName: string;
    processingActivityStatus: ProcessingActivityStatus;
}

export enum ProcessingActivityStatusEnum {
    notStarted = "0",
    inProgress = "1",
    completed = "2",
}

export enum ProcessingActivityStatus {
    Active = 0,
    Draft = 1,
    Archived = 2,
    DraftSubmitted = 3,
    AwaitingResearcher = 4,
    Cancelled = 5,
}
