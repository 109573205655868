import { createElement, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteHttp, get, put } from "../common/api/apiShared";
import { useStateUrlParamsArray, useStateUrlParamsNumber } from "../common/hooks/useStateUrlParams";
import { useNavigate, useParams } from "react-router-dom";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { LegalEntityModel } from "./addLegalEntityDialog/AddLegalEntityDialog.types";
import { useUrlProvider } from "../useUrlProvider";
import { SharingBaseTableViewModel } from "../processingActivity/components/sharingsTable/SharingsTable.types";
import { Trans } from "react-i18next";
import { LegalEntityMasterType } from "./legalEntities/LegalEntities.types";
import { ILegalEntitiesProps } from "./legalEntities/LegalEntities";
import { UserPermissions } from "../auth/userContextProvider/UserContextProvider.types";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import { exhaustiveGuard } from "../common/utilities";

export function useLegalEntityHook(props: ILegalEntitiesProps) {
    const { hasVendorManagementAccess } = usePlanContext();
    const [selectedTab, setSelectedTab] = useStateUrlParamsNumber("tab", hasVendorManagementAccess ? 0 : 1);
    const { translateCountry, translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const { id } = useParams<{ id: string }>();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { getLegalEntitiesUrl, getGroupEntitiesUrl, getCustomerLegalEntityUrl, getOtherLegalEntityUrl, getLegalEntityUrl } = useUrlProvider();
    const [showDeleteDialog, setDeleteShowDialog] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [showChangeCompanyStatusDialog, setShowChangeCompanyStatusDialog] = useState(false);
    const [showLegalEntityRiskQuestionnaireDialog, setShowLegalEntityRiskQuestionnaireDialog] = useState(false);
    const [showChangeCustomerOwnedDialog, setShowChangeCustomerOwnedDialog] = useState(false);
    const [showThirdCountryDialog, setShowThirdCountryDialog] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const queryKey = "legalEntity" + id;

    const saveNoteMutation = useMutation(updateLegalEntityNote);
    const updateNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: (resp: any) => {
                snackbar.show(translateString("noteUpdated"), "success");
                queryClient.fetchQuery(queryKey);
                setShowNoteDialog(false);
            },
        });
    };

    let legalEntityQuery = useQuery(queryKey, () => get<LegalEntityModel>(`/LegalEntity/${id}`));
    let sharingsQuery = useQuery("legalEntitySharing" + id, () => get<SharingBaseTableViewModel>(`/LegalEntity/privacySharings/${id}`));

    useEffect(() => {
        if (legalEntityQuery.data) {
            if (!legalEntityQuery.data.isCustomerOwned && !legalEntityQuery.data.masterTypes.includes(Number(props.legalEntityPage))) {
                switch (legalEntityQuery.data.masterTypes[0]) {
                    case LegalEntityMasterType.Customer:
                        navigate(getCustomerLegalEntityUrl(id!, selectedTab!), { replace: true });
                        break;
                    case LegalEntityMasterType.Other:
                        navigate(getOtherLegalEntityUrl(id!, selectedTab!), { replace: true });
                        break;
                    case LegalEntityMasterType.Vendor:
                        navigate(getLegalEntityUrl(id!, selectedTab!), { replace: true });
                        break;
                    case LegalEntityMasterType.GroupEntity:
                        break;
                    default:
                        exhaustiveGuard(legalEntityQuery.data.masterTypes[0]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legalEntityQuery.data]);

    let legalEntityLoading = legalEntityQuery.isLoading;
    let sharingsLoading = sharingsQuery.isLoading;

    let legalEntityData;
    if (legalEntityQuery.data) {
        legalEntityData = {
            name: legalEntityQuery.data.name,
            address: legalEntityQuery.data.address,
            country: translateCountry(legalEntityQuery.data.country),
            companyRegistrationNumber: legalEntityQuery.data.companyRegistrationNumber,
            isActive: legalEntityQuery.data.isActive,
            isCustomerOwned: legalEntityQuery.data.isCustomerOwned,
            note: legalEntityQuery.data.note,
            riskAssessment: legalEntityQuery.data.riskAssessment,
            zipCodeAndCity: (legalEntityQuery.data.zipCode ?? "") + " " + (legalEntityQuery.data.city ?? ""),
            types: legalEntityQuery.data.types,
            certifications: legalEntityQuery.data.certifications,
            areas: legalEntityQuery.data.areas,
            countryCode: legalEntityQuery.data.country,
            isTIAUnnecessary: legalEntityQuery.data.isTIAUnnecessary,
            isTransferBasisUnnecessary: legalEntityQuery.data.isTransferBasisUnnecessary,
            levelOfProtection: legalEntityQuery.data.levelOfProtection,
            isThirdCountry: legalEntityQuery.data.isThirdCountry,
            website: legalEntityQuery.data.website,
            responsible: legalEntityQuery.data.responsible,
            businessAreas: legalEntityQuery.data.businessAreas,
            auditType: legalEntityQuery.data.auditType,
            auditFrequency: legalEntityQuery.data.auditFrequency,
            numberOfDocuments: legalEntityQuery.data.numberOfDocuments,
        };
    }

    const refecthLegalEntity = () => {
        queryClient.fetchQuery(queryKey);
    };

    const deleteMutation = useMutation(deleteprocessingactivity, {
        onSuccess: (response: any) => {
            var resp = response.value();
            if (resp.isDeleted) {
                if (legalEntityQuery.data!.isCustomerOwned) navigate(getGroupEntitiesUrl());
                else if (legalEntityQuery.data!.isCustomerOwned === false) navigate(getLegalEntitiesUrl());

                snackbar.show(createElement(Trans, { i18nKey: "itemDeletedSuccess", values: { name: legalEntityData!.name } }));
            } else {
                setInProgress(false);
                setDeleteShowDialog(false);
                snackbar.show(createElement(Trans, { i18nKey: "deleteCompanyError", values: { name: resp.name } }), "warning");
            }
        },
    });

    const deleteLegalEntity = async () => {
        setInProgress(true);
        await deleteMutation.mutateAsync();
    };

    return {
        showDeleteDialog,
        setDeleteShowDialog,
        inProgress,
        legalEntityLoading,
        legalEntityData,
        sharingsLoading,
        sharingsData: sharingsQuery.data,
        anchorEl,
        setAnchorEl,
        open: Boolean(anchorEl),
        popoverId: Boolean(anchorEl) ? "simple-popover" : undefined,
        refecthLegalEntity,
        deleteLegalEntity,
        showNoteDialog,
        setShowNoteDialog,
        updateNote,
        noteSaving: saveNoteMutation.isLoading,
        showChangeCompanyStatusDialog,
        setShowChangeCompanyStatusDialog,
        /* hasSharings, */ showLegalEntityRiskQuestionnaireDialog,
        setShowLegalEntityRiskQuestionnaireDialog,
        selectedTab,
        setSelectedTab,
        showChangeCustomerOwnedDialog,
        setShowChangeCustomerOwnedDialog,
        showThirdCountryDialog,
        setShowThirdCountryDialog,
        legalEntityId: id,
    };

    function updateLegalEntityNote(note: string) {
        return put<{}>(`/legalentity/${id}/note`, note);
    }

    function deleteprocessingactivity() {
        return deleteHttp<string>("/legalentity/" + id);
    }
}

export function getLegalEntityPermissions(permissions: UserPermissions, isGroupEntity: boolean) {
    if (isGroupEntity) {
        return {
            read: permissions.canManageGroupEntities,
            create: permissions.canManageGroupEntities,
            edit: permissions.canManageGroupEntities,
            delete: permissions.canManageGroupEntities,
        };
    } else {
        return {
            read: permissions.companyPermissions.read,
            create: permissions.companyPermissions.create,
            edit: permissions.companyPermissions.edit,
            delete: permissions.companyPermissions.delete,
        };
    }
}
