import { useTranslation } from "../../localization/useTranslation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import { useIncidentLogOverviewDataMapping } from "./IncidentLogOverview.hooks";
import { useIncidentLogOverviewStyles } from "./IncidentLogOverview.styles";
import { Box, Grid, GridSize, IconButton, MenuItem, Popover, Skeleton } from "@mui/material";
import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs";
import React from "react";
import { DotLegalFixedHeightText, DotLegalHeader, DotLegalHeading, DotLegalInformationTooltip, DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { editIcon } from "../../common/icons";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import IncidentLogOverviewDataCategoriesDialog from "./IncidentLogOverviewDataCategoriesDialog";
import IncidentLogOverviewTypesDialog from "./IncidentLogOverviewTypesDialog";
import IncidentLogDeleteDialog from "../incidentLogDeleteDialog/IncidentLogDeleteDialog";
import CreateEditGdprIncidentLogDialog from "../incidentLogDialog/CreateEditGdprIncidentLogDialog";
import IncidentLogOverviewConsequencesDialog from "./IncidentLogOverviewConsequencesDialog";
import IncidentLogOverviewMeasuresDialog from "./IncidentLogOverviewMeasuresDialog";
import GdprIncidentDocumentTab from "./documents/GdprIncidentDocumentTab";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { SeverityLevelEnum } from "../IncidentLogDialog.types";
import IncidentLogRisk from "../../common/components/incidentLogRisk/IncidentLogRisk";

function IncidentLogOverview() {
    const { translateString, translateGdprIncidentType, translateDate, translateTime } = useTranslation();
    const userContext = useUserContext();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const {
        isLoading,
        data,
        anchorEl,
        setAnchorEl,
        selectedTab,
        setSelectedTab,
        open,
        popoverId,
        showDataCategoryDialog,
        setShowDataCategoryDialog,
        showIncidentTypeDialog,
        setShowIncidentTypeDialog,
        showDeleteDialog,
        setShowDeleteDialog,
        showEditDialog,
        setShowEditDialog,
        refetch,
        showConsequencesDialog,
        setShowConsequencesDialog,
        showSecurityMeasuresDialog,
        setShowSecurityMeasuresDialog,
    } = useIncidentLogOverviewDataMapping();
    const styles = useIncidentLogOverviewStyles();
    const propertiesGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: 2,
    };
    const getIncidentTypes = () => {
        let items = [];

        if (isLoading) {
            items.push(<Skeleton variant={"text"} />);
        } else {
            data?.types?.slice(0, 2).forEach((x, i) =>
                items.push(
                    <Box key={i} sx={styles.headerTextList}>
                        {translateGdprIncidentType(x.toString())}
                    </Box>
                )
            );

            if (data?.types?.length && data?.types?.length > 2) {
                items.push(
                    <Box sx={styles.headerTextList}>
                        <DotLegalHeader color={"secondary"} headerStyle={"extraSmall"} onClick={() => setShowIncidentTypeDialog(true)}>
                            <Box sx={styles.headerTextSeeAllOption}>{translateString("seeAll")}</Box>
                        </DotLegalHeader>
                    </Box>
                );
            }
        }

        return items;
    };

    const getDataCategories = () => {
        let items = [];

        if (isLoading) {
            items.push(<Skeleton variant={"text"} />);
        } else {
            data?.dataCategories?.slice(0, 2).forEach((x, i) =>
                items.push(
                    <Box key={i} sx={styles.headerTextList}>
                        {x}
                    </Box>
                )
            );

            if (data?.dataCategories?.length && data?.dataCategories?.length > 2) {
                items.push(
                    <Box sx={styles.headerTextList}>
                        <DotLegalHeader color={"secondary"} headerStyle={"extraSmall"} onClick={() => setShowDataCategoryDialog(true)}>
                            <Box sx={styles.headerTextSeeAllOption}>{translateString("seeAll")}</Box>
                        </DotLegalHeader>
                    </Box>
                );
            }
        }

        return items;
    };

    const getBreadCrumbs = () => {
        return [
            {
                name: translateString("incidentLog"),
                link: urlProvider.getIncidentLogUrl(),
            },
            {
                name: data?.name,
            },
        ];
    };

    const getTabContent = () => {
        const content: Array<TabContent> = [
            {
                label: translateString("documents"),
                content: getDocumentsTab(),
                index: 0,
            },
        ];

        return content;
    };

    const getBooleanAndDate = (isSet: boolean | null | undefined, date: Date | null | undefined) => {
        if (isSet === null) return <Box></Box>;
        if (isSet) {
            return (
                <React.Fragment>
                    <Box>{translateString("yes")}</Box>
                    {date && <Box>{translateDate(date)}</Box>}
                </React.Fragment>
            );
        }
        return <Box>{translateString("no")}</Box>;
    };

    const getDateAndTimeOfIncident = () => {
        return (
            <React.Fragment>
                {data?.dateOfIncident && <Box>{translateDate(data.dateOfIncident)}</Box>}
                {data?.timeOfIncident && <Box>{translateTime(data.timeOfIncident)}</Box>}
            </React.Fragment>
        );
    };

    const getDocumentsTab = () => {
        return <GdprIncidentDocumentTab incidentId={data?.id!} />;
    };

    const getConsequencesAndMeasures = () => {
        let items = [];

        if (data?.consequences) {
            items.push(
                <Box sx={styles.headerTextList} key={"consequences"}>
                    <DotLegalHeader
                        marginTop={0}
                        marginBottom={0}
                        color={"green"}
                        hoverable
                        headerStyle={"extraSmall"}
                        onClick={() => setShowConsequencesDialog(true)}
                    >
                        <Box sx={styles.headerTextSeeAllOption}>{translateString("incidentLogOverviewConsequencesHeader")}</Box>
                    </DotLegalHeader>
                </Box>
            );
        }

        if (data?.measures) {
            items.push(
                <Box sx={styles.headerTextList} key={"measures"}>
                    <DotLegalHeader
                        marginTop={0}
                        marginBottom={0}
                        color={"green"}
                        hoverable
                        headerStyle={"extraSmall"}
                        onClick={() => setShowSecurityMeasuresDialog(true)}
                    >
                        <Box sx={styles.headerTextSeeAllOption}>{translateString("incidentLogOverviewSecurityMeasuresHeader")}</Box>
                    </DotLegalHeader>
                </Box>
            );
        }

        return items;
    };
    // TODO - Efter permissions er lavet i frontend skal vi erstatte edit knap med <PageHeaderEditEntityButton
    // Se PolicyOverview for implementation
    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName: userContext.customerName }}
                breadCrumbs={getBreadCrumbs()}
                topLeftComponent={
                    data?.cause ? (
                        <Box marginLeft={1} marginTop={0.5}>
                            <DotLegalInformationTooltip text={data?.cause ?? ""} small />
                        </Box>
                    ) : (
                        <Box></Box>
                    )
                }
                rightComponent={
                    <Box sx={styles.gridContentAlignment}>
                        <Box sx={styles.editButton}>
                            {userContext.permissions.gdprIncidentLogPermissions.edit && (
                                <IconButton
                                    color={"primary"}
                                    aria-haspopup={"true"}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                                >
                                    {editIcon}
                                </IconButton>
                            )}
                            <Popover
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                id={popoverId}
                                open={open}
                            >
                                <React.Fragment>
                                    <MenuItem
                                        sx={styles.menuItem}
                                        onClick={() => {
                                            setAnchorEl(null);
                                            setShowEditDialog(true);
                                        }}
                                    >
                                        {translateString("edit")}
                                    </MenuItem>
                                    {userContext.permissions.gdprIncidentLogPermissions.delete && (
                                        <MenuItem
                                            sx={styles.menuItem}
                                            onClick={() => {
                                                setShowDeleteDialog(true);
                                            }}
                                        >
                                            {translateString("delete")}
                                        </MenuItem>
                                    )}
                                </React.Fragment>
                            </Popover>
                        </Box>
                    </Box>
                }
            />
            <DotLegalPaper background={"smallPurpleWave"} backgroundCover>
                <Grid sx={styles.gridRow} container spacing={3}>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("dateAndTime")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={getDateAndTimeOfIncident()} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("incidentTypeAndSeverity")}>
                            <DotLegalFixedHeightText
                                neverShowTooltip
                                isLoading={isLoading}
                                content={
                                    data ? (
                                        <>
                                            <Box>{getIncidentTypes()}</Box>
                                            <Box sx={{ display: "flex", gap: 1, alignItems: "cener", justifyContent: "center" }}>
                                                <Box>{translateString("severity")}:</Box> <IncidentLogRisk severityLevel={data.severity} />
                                            </Box>
                                        </>
                                    ) : undefined
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("incidentTableDataCategories")}>
                            <DotLegalFixedHeightText neverShowTooltip isLoading={isLoading} content={getDataCategories()} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("incidentTableReportedToAgency")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={getBooleanAndDate(data?.reportedToAgency, data?.dateOfReportedToAgency)} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("incidentTableAffectedPeopleNotified")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={getBooleanAndDate(data?.affectedPeopleNotified, data?.dateOfAffectedPeopleNotified)}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("incidentLogConsequencesAndMeasures")}>
                            <DotLegalFixedHeightText neverShowTooltip isLoading={isLoading} content={getConsequencesAndMeasures()} />
                        </DotLegalHeading>
                    </Grid>
                </Grid>
            </DotLegalPaper>
            <Box sx={styles.tableContainer}>
                <DotLegalTabs
                    fullWidth={false}
                    tabStyle="secondary"
                    selectedTab={Number(selectedTab[0])}
                    setSelectedTab={(val) => setSelectedTab([val.toString()])}
                    content={getTabContent()}
                    noPadding
                />
            </Box>
            {showDataCategoryDialog && (
                <IncidentLogOverviewDataCategoriesDialog onCloseDialog={() => setShowDataCategoryDialog(false)} dataCategories={data?.dataCategories} />
            )}

            {showIncidentTypeDialog && <IncidentLogOverviewTypesDialog onCloseDialog={() => setShowIncidentTypeDialog(false)} types={data?.types} />}

            {showConsequencesDialog && (
                <IncidentLogOverviewConsequencesDialog onCloseDialog={() => setShowConsequencesDialog(false)} content={data?.consequences!} />
            )}

            {showSecurityMeasuresDialog && (
                <IncidentLogOverviewMeasuresDialog onCloseDialog={() => setShowSecurityMeasuresDialog(false)} content={data?.measures!} />
            )}

            {showDeleteDialog && (
                <IncidentLogDeleteDialog
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    name={data?.name!}
                    id={data?.id!}
                    onSuccessDelete={() => {
                        navigate(urlProvider.getIncidentLogUrl());
                    }}
                />
            )}

            {showEditDialog && (
                <CreateEditGdprIncidentLogDialog
                    id={data?.id!}
                    closeDialog={() => setShowEditDialog(false)}
                    onSave={async () => {
                        setShowEditDialog(false);
                        await refetch();
                    }}
                />
            )}
        </Box>
    );
}

export default IncidentLogOverview;
