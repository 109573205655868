import React, { Component } from "react";
import { Box, IconButton, MenuItem, Popover } from "@mui/material";
import { editIcon } from "../../icons.tsx";
import { usePageHeaderEditEntityButton } from "./PageHeaderEditEntityButton.hooks.ts";

export interface PageHeaderEditEntityButtonMenuItem {
    title: string;
    onClick: () => void;
}

export interface PageHeaderEditEntityButtonProps {
    menuItems: Array<PageHeaderEditEntityButtonMenuItem>;
}

function PageHeaderEditEntityButton(props: PageHeaderEditEntityButtonProps) {
    const { anchorEl, setAnchorEl, open, popoverId } = usePageHeaderEditEntityButton();

    if (props.menuItems.length === 0) {
        return undefined;
    }

    return (
        <Box
            sx={(theme) => ({
                "& svg": {
                    height: 25 + "px",
                    stroke: theme.palette.primary.main,
                    fill: theme.palette.primary.light,
                },
            })}
        >
            <React.Fragment>
                <IconButton
                    color={"primary"}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                >
                    {editIcon}
                </IconButton>
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{ vertical: "center", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    {props.menuItems.map((menuItem, index) => {
                        return (
                            <MenuItem
                                key={index}
                                sx={(theme) => ({ color: theme.palette.primary.dark, fontSize: "0.875rem", fontWeight: theme.typography.fontWeightMedium })}
                                onClick={() => {
                                    setAnchorEl(null);
                                    menuItem.onClick();
                                }}
                            >
                                {menuItem.title}
                            </MenuItem>
                        );
                    })}
                </Popover>
            </React.Fragment>
        </Box>
    );
}

export default PageHeaderEditEntityButton;
