import { useState } from "react";
import { ISharingsAgreementBox } from "./sharingsAgreementBox";

export function useSharingsAgreementBoxHooks(props: ISharingsAgreementBox) {
    const [showAddAgreementDialog, setShowAddAgreementDialog] = useState(false);

    return {
        showAddAgreementDialog,
        setShowAddAgreementDialog,
    };
}
