import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { getEnumValues } from "../../common/enumOperations";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { PolicyRowModel, PolicySaveModel, PolicyType } from "./Policies.types";
import { IsPolicyLimitReached, RefetchPolicyCountQuery } from "../../auth/planProvider/PlanProvider.hooks";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";

export function usePoliciesDataMapping() {
    const url = `/policies`;
    const { translatePolicyTypes } = useTranslation();
    const [showPolicyDialog, setShowPolicyDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState<PolicySaveModel>(getDefaultPolicy());
    const [searchedPolicyTypes, setSearchedPolicyTypes] = useStateUrlParamsArray("policyTypes", []);
    const policyLimitReached = IsPolicyLimitReached();
    const refetchPolicyCount = RefetchPolicyCountQuery();
    const responsibleQuery = useResponsibleQuery();

    const { isLoading, data, refetch } = useQuery(url, () => get<Array<PolicyRowModel>>(url));

    let policyTypeOptions: Array<DotLegalSelectOption> = [];

    let policyData;
    const hasPolicyData = isLoading || data!.length > 0;
    if (data) {
        getPolicyTypeOptions();
        policyData = assignFilteredPolicies().map((x) => ({
            ...x,
            responsible: responsibleQuery.data(x.responsibleId)?.find((y) => x.responsibleId === y.id)?.name,
        }));
    }

    function getPolicyTypeOptions() {
        return getEnumValues(PolicyType).forEach((x) => {
            const name = translatePolicyTypes([x].toString());
            if (!policyTypeOptions.some((p) => p.id === x.toString())) {
                const policy = data!.map((d) => PolicyType[d.type].toString()).find((t) => t === PolicyType[x].toString());
                if (policy) {
                    policyTypeOptions.push({ name: name, id: x.toString() });
                }
            }
        });
    }

    function assignFilteredPolicies() {
        let tempData = [...data!];

        if (searchedPolicyTypes.length > 0 && !isNullOrWhitespace(searchedPolicyTypes[0])) {
            tempData = tempData.filter((p) => searchedPolicyTypes.includes(p.type.toString()));
        }

        return tempData;
    }

    function getDefaultPolicy(): PolicySaveModel {
        return { id: "", name: "", type: undefined, responsibleId: undefined, tags: [] };
    }

    function onRefetch() {
        refetch();
        refetchPolicyCount();
    }

    return {
        policyData,
        isLoading,
        hasPolicyData,
        refetch,
        showPolicyDialog,
        setShowPolicyDialog,
        showDeleteDialog,
        setShowDeleteDialog,
        selectedPolicy,
        setSelectedPolicy,
        getDefaultPolicy,
        searchedPolicyTypes,
        setSearchedPolicyTypes,
        policyTypeOptions,
        policyLimitReached,
        onRefetch,
        selectableResponsibles: responsibleQuery.data,
        isResponsiblesLoading: responsibleQuery.isLoading,
    };
}
